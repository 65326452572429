
/**
 * Retourne le nombre reçu en entrée au format :
 * - 2 décimales si le nombre en possède moins de 2 ou pas.
 * - inchangé si le nombre possède 3 décimales ou plus
 * @param {Number} value
 * @returns
 */
export function numberWithMinTwoDecimal(value) {

  if (value) {

    // Si le nombre à plus de 2 décimale, on le retourne tel quel
    if (numberOfDecimal(value) > 2) {
      return value;
    } else {
      // Si le nombre à 2 décimale ou moins on lui force 2 décimales
      return value.toFixed(2);
    }
  }
}

/**
 * Détermine le nombre de décimale d'un nombre
 * @param {*} value 
 * @returns 
 */
export function numberOfDecimal(value) {
  let nbDecimal = null;
  if (value) {
    // Compare le plus grand entier  avec la valeur, si vrai le nombre n'a pas de décimale
    if (Math.floor(value) === value) {
      nbDecimal = 0;
    } else if (value.toString().includes('.')) {
      // Conversion en string du nombre, puis split à partir du point, et compte le nombre de décimale du nombre
      nbDecimal = value.toString().split(".")[1].length;
    } else {
      nbDecimal = 0;
    }
    
  }
  return nbDecimal;
}


/**
 * Convertit un nombre en string en adaptant le '.' en ','.
 */
export function numberToString(value) {
  if (value) {
    // Convertit le type number en string
    let result = value.toString();
    // Remplace le . par une virgule
    result = result.replace('.', ',');

    return result;

  } else {
    return value;
  }
}

/**
 * Arrondi au 100eme de décimale le nombre passé en paramètre et convertit en chaîne
 */
export function numberWithMaxTwoDecimal(value) {
  if (value !== '') {
    const number = parseFloat(value);
    if (!isNaN(number)) {
      const roundedValue = Math.round(number * 100) / 100;
      return roundedValue.toString();
    }
  }
}
