import ApiService from "../api_service";
import * as statusMapper from "@/service/error_request_mapper.js";

export class AvecProductsService extends ApiService {
  constructor(api) {
    super(api);
  }

  /** Récupération de l'ensemble des descriptioons spécifiques */
  async getAllSpecificDescription(productId) {
    return this.api
      .getAllSpecificDescription(productId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }


  /** Mise à jour d'un tarif de produit pour un établissement */
  async createUpdateSpecificDescription(establishmentId, productId, datas) {
    return this.api
      .createUpdateSpecificDescription(establishmentId, productId, datas)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(datas);
      converter.convert(error);
    });
  }


   /** Suppression d'un tarif de produit pour un établissement */
   async deleteSpecificDescription(establishmentId, productId) {
    return this.api
      .deleteSpecificDescription(establishmentId, productId)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(establishmentId);
      converter.convert(error);
    });
  }
}
